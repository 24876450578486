.container {
    min-height: 100vh;
}

.canvas {
    min-height: 300px;
    max-height: 700px;
    width: 100vw;
    background-color: black;
    display: block;
}

.money {
    position: absolute;
    width: 100vw;
    display: flex;
    justify-content: center;
    top: 30px;
    color: white;
    z-index: 100;
}

.betContainer {
    width: 100vw;
    max-height: 50px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    bottom: 0px;
}

.changeBet {
    margin-left: 20px;
}

.addBet {
    background-color: green;
    padding: 2px;
}

.reduceBet {
    background-color: red;
    padding: 2px;
}

.winningText {
    font-size: 3rem;  
    position: absolute;
    color: white;
    top: 47vh;  
    width: 100vw;
    display: flex;
    justify-content: center;
    background-color: blue;
    white-space: pre-line;
    animation: win 1s infinite;
    transition: all 1s;
    z-index: 100;
}

@keyframes win {
    0% {
        transform:  scale(1);
    }
    50% {
        transform: scale(1.3);
    }
    100% {
        transform: scale(1);
    }
}

.rulesContainer {
    position: absolute;
    left: 2%;
    top: calc(50% - 80px);
    max-width: 300px;
    padding: 10px 20px;
    background-color: greenyellow;
    border: 5px solid green;
    z-index: 99;
}

@media screen and (max-width: 1000px){
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 160vh;
        background-color: black;
    }

    .rulesContainer {
        position: relative;
        top: 0;
    }
}

@media screen  and (max-height: 300px){
    .betContainer {
        top: 300px
    }
}

@media screen and (min-height: 700px) {
    .betContainer {
        top: 650px
    }
}

